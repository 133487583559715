import React from 'react'
import Layout from '../components/layout'

export default ({pageContext}) => (
	<Layout>
		<h1>
			{pageContext.title}
		</h1>
		<h1>
			{pageContext.title}
		</h1>
		<h1>
			{pageContext.title}
		</h1>
		<h1>
			{pageContext.title}
		</h1>
		    

			<div>{pageContext.acf.client_name}</div>
			<div>{pageContext.acf.project_name}</div>
			<div>{pageContext.acf.website_url}</div>
			<div>{pageContext.acf.the_challenge}</div>
			<div>{pageContext.acf.the_solution}</div>
			<div>{pageContext.acf.the_result}</div>
			<div>{pageContext.acf.lets_work_tagline}</div>
			<div>{pageContext.acf.lets_work_button}</div>
			<div>{pageContext.acf.lets_work_tagline}</div>
			<div>{pageContext.acf.client_company}</div>
	</Layout>
);